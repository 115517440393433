import React from 'react';
import ipad from '../assets/images/ipad.png';
import Header from '../components/huntHeader';
import Layout from '../components/Layout';




const Clue7 = () => (
  <Layout>
    <Header /> 

    <header className="masthead6">
    <br></br>
    <br></br>
      <div className="container d-flex h-100 align-items-center">
        <div className="text-white-50 mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">Clue #7</h1>
          <br></br>
          <br></br>
          <h2 className="tracking-in-expand mx-auto mt-2 mb-5">
            {/* {config.subHeading} */}
            Just for a weekend
            <br></br>
            sometimes it's more.
            <br></br>
            <br></br>
            Within me you will find
            <br></br>
            many a floor. 
            <br></br>
            <br></br>
            I can’t get around
            <br></br>
            so here I will stay.
            <br></br>
            <br></br>
            The longer you are with me
            <br></br>
            the more you will pay.
            <br></br>
            <br></br>
            When it is over   
            <br></br>
            you must bid farewell.
            <br></br>
            <br></br>
            From this place
            <br></br>
            once named after a shell.
            <br></br>
          </h2>
          {/* <Scroll type="id" element="about">
            <a href="#about" className="btn btn-primary">
              Hint
            </a>
          </Scroll> */}
            <a href="/hint7" className="btn btn-primary">
              Hint
            </a> 
            <br></br>          
        </div>
      </div>
    </header>

  {/* <Subscribe /> */}

    {/* <SocialLinks />
    <Footer /> */}
  </Layout>
);

export default Clue7;
